import * as React from "react";
import Container from "react-bootstrap/Container";
import TexasOutline from "../../../assets/state-of-texas.svg";
import "./LocationsBit.scss";

const LocationsBit = () => {
  return (
    <section className="locationsBit">
      <Container className="text-center">
        <h2 className="sectionHeading">
          <span className="heading">Locations</span>
        </h2>
      </Container>
      <Container className="locationsBit__cont">
        <div className="multiBox__left">
          <div className="multiBox__inset">
            We are located in <strong>Seguin, Texas</strong> but we can venture
            out to San Antonio, Austin and the surrounding area to provide
            training to multiple individuals. We try to conduct classes with
            several students at the same time at specific locations, so we are
            willing to venture outside of Texas if the logistics make sense.
            Send us a note if you are interested, as we would love to discuss
            your training needs and resources.
          </div>
        </div>
        <div className="multiBox__right">
          <TexasOutline />
        </div>
      </Container>
    </section>
  );
};

export default LocationsBit;
